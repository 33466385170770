import * as React from "react";
import CssBaseline from "@mui/material/CssBaseline";
import Link from "@mui/material/Link";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import {
  DataGridPro,
  GridColDef,
  GridPaginationModel,
} from "@mui/x-data-grid-pro";
import {
  Stack,
  Tooltip,
  IconButton,
  Button,
  FormControl,
  InputAdornment,
  TextField,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
} from "@mui/material";
import NoRows from "../../components/DataGrid/animations/NoRows";
import EditIcon from "@mui/icons-material/Edit";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import VisibilityIcon from "@mui/icons-material/Visibility";
import Header from "../../components/Header";
import axiosInstance from "../../utils/axiosInstance";
import { useNavigate } from "react-router-dom";
import SearchIcon from "@mui/icons-material/Search";
import AddIcon from "@mui/icons-material/Add";
import moment from "moment";

function Copyright(props: any) {
  return (
    <Typography
      variant="body2"
      color="text.secondary"
      align="center"
      sx={{ mt: (theme) => theme.spacing(12), mb: (theme) => theme.spacing(4) }}
      {...props}
    >
      {"Copyright © "}
      <Link color="inherit" href="https://highlandhomes.com/">
        Highland Homes
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

const theme = createTheme();

type FilterItem = {
  id?: string | number;
  field: string;
  operator: string;
  value: string | string[]; // Allow value to be string or array of strings
};

type Filters = {
  items: FilterItem[];
  quickFilterValues: string[];
};

const defaultFilter: Filters = {
  items: [],
  quickFilterValues: [""],
};

export default function History() {
  const navigation = useNavigate();
  const [rows, setRows] = React.useState<
    {
      analysisHistoryCount: number;
      buyer: string;
      buyerCombined: string;
      jobAddress: string;
      jobId: number;
      jobNumber: string;
      planDescription: string;
      planElevationDescription: string;
      planElevationNumber: string;
      planNumber: string;
      profitAnalysisId: number;
      profitAnalysisType: string;
      projectId: number;
      projectNumber: string;
      soldDate: string;
      startDate: string;
      subdivision: string;
      stageCode: string;
      stageDate: string;
      profitMargin: number;
      role:string;
    }[]
  >([]);
  const [loading, setLoading] = React.useState<boolean>(false);
  const [filt, setFilt] = React.useState<Filters>(defaultFilter);
  const [stage, setStage] = React.useState<string>("All");
  const [paginationModel, setPaginationModel] = React.useState({
    page: 0,
    pageSize: 50,
  });

  const columns: GridColDef[] = [
    {
      field: "jobAddress",
      headerName: "Address",
      minWidth: 260,
      flex: 1,
      filterable: true,
      renderCell: (params) => (
        <Tooltip
          title={params.row.profitAnalysisId === 0 
            ? "Create" :
              params.row.role === "Write" ? "Revise" : "View"}>
          <Link
            color="inherit"
            sx={{
              cursor: "pointer",
            }}
            href={
              params.row.profitAnalysisId === 0
                ? ``
                : params.row.role === "Write" 
                ? `/edit/${params.row.profitAnalysisId}`
                : `/view/${params.row.profitAnalysisId}`
            }
            onClick={() => {
              if (params.row.profitAnalysisId === 0) {
                navigation("/create", {
                  state: { ...params.row, method: "fromHist" },
                });
              }
            }}
          >
            {params.value}
          </Link>
        </Tooltip>
      ),
    },
    {
      field: "subdivision",
      headerName: "Subdivision",
      minWidth: 200,
      flex: 1,
      filterable: true,
    },
    {
      field: "jobNumber",
      headerName: "Job #",
      headerAlign: "left",
      align: "left",
      minWidth: 120,
      filterable: true,
      valueGetter: (params) => {
        return `${params.row.projectNumber}-${params.row.jobNumber}`;
      },
    },
    {
      field: "buyerCombined",
      headerName: "Buyer",
      minWidth: 380,
      filterable: true,
    },
    {
      field: "stageCode",
      headerName: "Stage",
      filterable: true,
      minWidth: 120,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "profitMargin",
      headerName: "Profit Projection %",
      filterable: true,
      minWidth: 140,
      align: "center",
      renderCell: (row) => <div>{`${row.value}%`}</div>,
    },
    {
      field: "projectedCloseDate",
      headerName: "Projected Closed Date",
      minWidth: 170,
      valueFormatter: (params) => moment(params.value).format("L"),
      filterable: true,
      align: "center",
      renderCell: (params) => (
        <div>
          {params.row.profitAnalysisId === 0
            ? ""
            : moment(params.row.projectedCloseDate).format("L")}
        </div>
      ),
    },
    {
      field: "profitAnalysisId",
      headerName: "Actions",
      valueFormatter: (params) => {
        return params.value === 0 ? false : true;
      },
      align: "right",
      headerAlign: "center",
      minWidth: 100,
      renderCell: (params) => {
        return (
          <Stack direction="row" spacing={0.5} mr={2}>
            {params.row.profitAnalysisId !== 0 && 
              params.row.role === "Read" && (
              <Tooltip title={`View PA for ${params.row.jobAddress}`}>
                <IconButton href={`/view/${params.row.profitAnalysisId}`}>
                  <VisibilityIcon />
                </IconButton>
              </Tooltip>
            )}
            {params.row.profitAnalysisId !== 0 && 
              params.row.role === "Write" && (
              <Tooltip title={`Revise PA for ${params.row.jobAddress}`}>
                <IconButton href={`/edit/${params.row.profitAnalysisId}`}>
                  <EditIcon />
                </IconButton>
              </Tooltip>
            )}
            {params.row.profitAnalysisId === 0 && 
              params.row.role === "Write" && (
              <Tooltip title={`Create PA for ${params.row.jobAddress}`}>
                <IconButton
                  onClick={() => {
                    navigation("/create", {
                      state: { ...params.row, method: "fromHist" },
                    });
                  }}
                >
                  <AddCircleIcon />
                </IconButton>
              </Tooltip>
            )}
          </Stack>
        );
      },
    },
  ];

  const loadJobs = () => {
    setLoading(true);
    axiosInstance
      .get(`/api/jobs/`)
      .then((res) => {
        let uniqueIds = {};

        // Use the filter method to filter out objects with unique IDs
        const uniqueObjects = res.data.filter((obj: any) => {
          //@ts-ignore
          if (!uniqueIds[obj.jobId]) {
            //@ts-ignore
            uniqueIds[obj.jobId] = true;
            return true;
          }
          return false;
        });

        setRows(uniqueObjects as any);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        console.error(err);
      });
  };

  const getDetails = (id: string) => {
    axiosInstance
      .get(`/api/profit-analyses/${id}`)
      .then((res) => {
        navigation("/edit", { state: { ...res.data } });
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const updateFilter = (field: string, value: string | string[]): void => {
    setFilt((prevFilt) => {
      // Filter out the existing filter item for the given field
      const otherItems = prevFilt.items.filter((item) => item.field !== field);

      // Determine the new filter item
      let newItems: {
        id: string;
        field: string;
        operator: string;
        value: string | string[];
      }[] = [];
      if (value !== "All") {
        newItems = [
          {
            id: `${field}-${Array.isArray(value) ? "multi" : value}`,
            field,
            operator: Array.isArray(value) ? "isAnyOf" : "equals",
            value,
          },
        ];
      }

      // Return the updated filter state
      return { ...prevFilt, items: [...otherItems, ...newItems] };
    });
  };

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = event.target.value;
    setFilt((prevFilt) => ({
      ...prevFilt,
      quickFilterValues: [newValue],
    }));
  };

  const handleStageChange = (event: SelectChangeEvent<string>) => {
    const newValue = event.target.value;
    setStage(newValue);
    updateFilter("stageCode", newValue);
  };

  React.useEffect(() => {
    loadJobs();
  }, []);

  console.log(filt);

  return (
    <ThemeProvider theme={theme}>
      <Header />
      <Container component="main" maxWidth={false}>
        <CssBaseline />
        <Box
          width={"100%"}
          sx={{
            mt: (theme) => theme.spacing(10),
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Box
            sx={{
              height: "75vh",
              width: "100%",
              mt: (theme) => theme.spacing(2),
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: { sm: "column", md: "row" },
                justifyContent: "space-between",
                py: (theme) => theme.spacing(1),
                my: (theme) => theme.spacing(1.5),
              }}
            >
              <Stack
                sx={{
                  width: {
                    md: "60%",
                    lg: "50%",
                  },
                }}
                direction="row"
                alignItems="flex-start"
                spacing={1}
              >
                <FormControl
                  fullWidth
                  sx={{
                    maxWidth: 300,
                  }}
                >
                  <TextField
                    sx={{ maxWidth: "lg", width: "100%" }}
                    size="small"
                    label="Search"
                    variant="outlined"
                    onChange={handleSearchChange}
                    placeholder="Search..."
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <SearchIcon color="info" />
                        </InputAdornment>
                      ),
                    }}
                  />
                </FormControl>
                <FormControl
                  fullWidth
                  sx={{
                    maxWidth: 160,
                  }}
                >
                  <InputLabel>Stage</InputLabel>
                  <Select
                    value={stage}
                    label="Stage"
                    size="small"
                    onChange={handleStageChange}
                  >
                    <MenuItem key="All" value="All">
                      All
                    </MenuItem>
                    <MenuItem key="00" value="00">
                      00
                    </MenuItem>
                    <MenuItem key="01" value="01">
                      01
                    </MenuItem>
                    <MenuItem key="02" value="02">
                      02
                    </MenuItem>
                    <MenuItem key="03" value="03">
                      03
                    </MenuItem>
                    <MenuItem key="04" value="04">
                      04
                    </MenuItem>
                    <MenuItem key="05" value="05">
                      05
                    </MenuItem>
                    <MenuItem key="06" value="06">
                      06
                    </MenuItem>
                    <MenuItem key="07" value="07">
                      07
                    </MenuItem>
                    <MenuItem key="08" value="08">
                      08
                    </MenuItem>
                    <MenuItem key="09" value="09">
                      09
                    </MenuItem>
                    <MenuItem key="10" value="10">
                      10
                    </MenuItem>
                  </Select>
                </FormControl>
              </Stack>
              <Stack
                direction="row"
                alignItems="flex-end"
                justifyContent="flex-end"
                spacing={2}
                sx={{
                  flex: 1,
                  direction: "row",
                  mt: { sm: 2, md: 0 },
                }}
              >
                <Button
                  variant="contained"
                  href="/create"
                  startIcon={<AddIcon />}
                >
                  Create PA
                </Button>
              </Stack>
            </Box>

            <DataGridPro
              sx={{
                mt: (theme) => theme.spacing(2),
              }}
              disableColumnSelector
              disableDensitySelector
              initialState={{
                pagination: { paginationModel: { pageSize: 25 } },
              }}
              getRowId={(row) => row.jobId}
              paginationModel={paginationModel}
              paginationMode="server"
              onPaginationModelChange={(model: GridPaginationModel) =>
                setPaginationModel({
                  page: model.page,
                  pageSize: model.pageSize,
                })
              }
              filterModel={filt}
              onFilterModelChange={(newFilterModel) =>
                setFilt(newFilterModel as any)
              }
              disableRowSelectionOnClick
              columns={columns}
              rows={rows}
              rowCount={rows.length}
              slots={{
                noRowsOverlay: NoRows,
                // toolbar: GridToolbar,
              }}
              slotProps={{
                toolbar: {
                  csvOptions: { disableToolbarButton: true },
                  printOptions: { disableToolbarButton: true },
                  showQuickFilter: false,

                  density: "comfortable",
                  sx: {
                    padding: (theme) => theme.spacing(2),
                  },
                },
              }}
              loading={loading}
            />
          </Box>
        </Box>
        <Copyright />
      </Container>
    </ThemeProvider>
  );
}
