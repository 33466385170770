import React from "react";
import { AppBar, Toolbar, Box, Typography, Stack, Button } from "@mui/material";
import logo from "../../assets/images/logo-white.png";
import { Link } from "react-router-dom";
import { useMsal } from "@azure/msal-react";

type Props = {
  headerRight?: React.ReactNode;
};

const Header = (props: Props) => {
  const { instance } = useMsal();

  return (
    <AppBar
      position="fixed"
      sx={{
        zIndex: (theme) => theme.zIndex.drawer + 1,
        backgroundColor: (theme) => theme.palette.primary.main,
      }}
    >
      <Toolbar>
        <Box display="flex" flex={1} justifyContent="space-between">
          <Stack direction="row" spacing={1}>
            <Link to="/">
              <img src={logo} height={60} alt="logo" />
            </Link>
            <Box
              mx={2}
              flex={1}
              display="flex"
              flexDirection="column"
              justifyContent="center"
            >
              <Typography variant="h6">Profit Analysis</Typography>
            </Box>
          </Stack>

          <Button
            variant="outlined"
            sx={{ color: (theme) => theme.palette.common.white }}
            onClick={() =>
              instance.logoutRedirect({
                postLogoutRedirectUri: "/",
              })
            }
          >
            Logout
          </Button>
        </Box>
      </Toolbar>
    </AppBar>
  );
};

export default Header;
