import React, { useContext } from "react";
import { Snackbar } from "@mui/material";
import { UIContext } from "../../providers/UIProvider";
import MuiAlert, { AlertProps } from "@mui/material/Alert";

const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(
  props,
  ref: any
) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const SnackbarAlert: React.FC = () => {
  const [state, dispatch]: any = useContext(UIContext);
  const handleOpen = () => {
    dispatch({
      type: "Snackbar",
      payload: {
        show: false,
        message: "An Error Happened",
        severity: "error",
      },
    });
  };

  return (
    <>
      {state.snackbar.show && (
        <Snackbar
          sx={{
            width: "100%",
          }}
          autoHideDuration={10000}
          anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
          open={state.snackbar.show}
          onClose={handleOpen}
          message={state.snackbar.message}
        >
          <Alert
            severity={
              state.snackbar.severity ? state.snackbar.severity : "error"
            }
          >
            {state.snackbar.message}
          </Alert>
        </Snackbar>
      )}
    </>
  );
};
export default SnackbarAlert;
