import React, { useState } from "react";
import CssBaseline from "@mui/material/CssBaseline";
import Container from "@mui/material/Container";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import {
  Box,
  Divider,
  Drawer,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Toolbar,
  Tooltip,
  Typography,
  Icon,
  TextField,
  Grid,
  Stack,
  Button,
  Accordion,
  AccordionSummary,
  InputAdornment,
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
} from "@mui/material";
import axiosInstance from "../../utils/axiosInstance";
import Header from "../../components/Header";
import TextSnippetIcon from "@mui/icons-material/TextSnippet";
import ConstructionIcon from "@mui/icons-material/Construction";
import CabinIcon from "@mui/icons-material/Cabin";
import LandscapeIcon from "@mui/icons-material/Landscape";
import TuneIcon from "@mui/icons-material/Tune";
import RequestQuoteIcon from "@mui/icons-material/RequestQuote";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { numberWithCommas } from "../../utils/formatMoney";
import { useAccount, useMsal } from "@azure/msal-react";
import moment from "moment";
import { generatePDF, pdfProps } from "../../utils/generatePDF";
import { useNavigate , useParams } from "react-router-dom";
import {
  ContractSectionProps,
  GenInfoProps,
  JobProps,
  RunningTotalsProps,
  SoftCostSectionProps,
} from "../../types";
import ConfirmationDialog from "../../components/ConfirmationDialog";
import { grey } from '@mui/material/colors';

const theme = createTheme({
  components: {
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          background: grey[300],
          color: grey[900],
          fontSize: "1em"
        },
      },
    },
  },
});

const drawerWidth = 240;

const navbarItems = [
  { id: 0, section: "Contract", href: "#contract", icon: <TextSnippetIcon /> },
  { id: 1, section: "Labor", href: "#labor", icon: <ConstructionIcon /> },
  { id: 2, section: "Material", href: "#material", icon: <CabinIcon /> },
  { id: 3, section: "Lot Extras", href: "#lotExtras", icon: <LandscapeIcon /> },
  { id: 4, section: "Options", href: "#options", icon: <TuneIcon /> },
  {
    id: 5,
    section: "Soft Cost",
    href: "#softCost",
    icon: <RequestQuoteIcon />,
  },
];

export default function Edit() {
  const navigate = useNavigate();
  const { accounts } = useMsal();
  let { id } = useParams();
  const account = useAccount(accounts[0]);
  const [mode, setMode] = React.useState<"edit" | "review">("edit");
  const [lotPremium, SetLotPremium] = React.useState<string>("");
  const [jobs, setJobs] = React.useState<JobProps[]>([]);
  const [pdfData, setPdfData] = React.useState<pdfProps | undefined>(undefined);
  // const [selectedJob, setSelectedJob] = React.useState<JobProps>();
  const [runningTotals, setRunningTotals] = React.useState<RunningTotalsProps>({
    contract: 0,
    labor: 0,
    material: 0,
    lotExtras: 0,
    options: 0,
    laborMaterial: 0,
    softCost: 0,
    totalPrice: 0,
    profitUSD: 0,
    profitMargin: 0,
    varianceToExitStrategy:0,
  });

  const minDate = moment();

  const [genInfo, setGenInfo] = React.useState<GenInfoProps>({
    Subdivision: "",
    StreetAddress: "",
    JobId: 0,
    ProjectId: 0,
    ProjectNumber: "",
    JobNumber: "",
    Buyer: "",
    PlanElevationNumber: "",
    PlanNumber: "",
    ProfitAnalysisType: "",
    ExitStrategy: 0,
    DateSold: moment() as any,
    ProjectedCloseDate: moment().startOf('day'),
    //@ts-ignore
    CurrentUser: account.name as string,
    SalesPrice: 0,
  });

  const [contractSection, setContractSection] =
    React.useState<ContractSectionProps>({
      BrochurePrice: 0,
      LotPremium: 0,
      OptionalArchitecturalPlanChanges: 0,
      CustomArchitecturalPlanChanges: 0,
      BuyerSelectedOptions: 0,
      OptionsGiven: 0,
      OptionsRetailValue: 0,
      CashOptionsPaid: 0,
      SalesAllowanceOptions: 0,
      SalesAllowanceClosingCosts: 0,
      SalesAllowancePriceReduced: 0,
    });

  const [laborSection, setLaborSection] = React.useState<{
    LaborBudget: number | undefined;
    ExtraLaborActual: number | undefined;
    SpecialLabor: number | undefined;
  }>({
    LaborBudget: 0,
    ExtraLaborActual: 0,
    SpecialLabor:0,
  });

  interface FormErrors {
    // ContractSection
    BrochurePrice: string;
    LotPremium: string;
    OptionalArchitecturalPlanChanges: string;
    CustomArchitecturalPlanChanges: string;
    BuyerSelectedOptions: string;
    OptionsGiven: string;
    OptionsRetailValue: string;
    CashOptionsPaid: string;
    SalesAllowanceOptions: string;
    SalesAllowanceClosingCosts: string;
    SalesAllowancePriceReduced: string;

    // laborSection
    LaborBudget: string;
    ExtraLaborActual: string;
    SpecialLabor:string;

    // MaterialSection
    MaterialBudget: string;
    ExtraMaterialBudget: string;
    SpecialMaterial:string;

    // LotExtrasSection
    LotExtras: string;

    // OptionsSection
    CostOfOptionsWithContract: string;

    // SoftCostSection
    LotCost: string;
    Discretionary: string;
    RealtorCommissions: string;
    SpecialMarketingIncentive: string;
    RealtorCommissionsadjusted: string;
    InHouseCommissions: string;
    InterimInterestBudget: string;
    ClosingCostBudget: string;
    BuilderBonus: string;
  }

  const [formErrors, setFormErrors] = React.useState<FormErrors>({
    // ContractSection
    BrochurePrice: "",
    LotPremium: "",
    OptionalArchitecturalPlanChanges: "",
    CustomArchitecturalPlanChanges: "",
    BuyerSelectedOptions: "",
    OptionsGiven: "",
    OptionsRetailValue: "",
    CashOptionsPaid: "",
    SalesAllowanceOptions: "",
    SalesAllowanceClosingCosts: "",
    SalesAllowancePriceReduced: "",

    // LaborSection
    LaborBudget: "",
    ExtraLaborActual: "",
    SpecialLabor:"",

    // MaterialSection
    MaterialBudget: "",
    ExtraMaterialBudget: "",
    SpecialMaterial:"",

    // LotExtrasSection
    LotExtras: "",

    // OptionsSection
    CostOfOptionsWithContract: "",

    // SoftCostSection
    LotCost: "",
    Discretionary: "",
    RealtorCommissions: "",
    SpecialMarketingIncentive: "",
    RealtorCommissionsadjusted: "",
    InHouseCommissions: "",
    InterimInterestBudget: "",
    ClosingCostBudget: "",
    BuilderBonus: "",
  });

  const updateField = (field: keyof FormErrors, value: string) => {

    switch (field) {
      // ContractSection
      case "BrochurePrice":
        setContractSection({
          ...contractSection,
          BrochurePrice: Math.abs(parseInt(value)),
        });
        break;

      case "LotPremium":
        if (value === '' || /^-?\d*\d+$/.test(value)) {
          SetLotPremium(value);
          setContractSection(prevState => ({
            ...prevState,
            LotPremium: parseInt(value)
          }));
        }
        break;

      case "OptionalArchitecturalPlanChanges":
        setContractSection({
          ...contractSection,
          OptionalArchitecturalPlanChanges: Math.abs(parseInt(value)),
        });
        break;

      case "CustomArchitecturalPlanChanges":
        setContractSection({
          ...contractSection,
          CustomArchitecturalPlanChanges: Math.abs(parseInt(value)),
        });
        break;

      case "BuyerSelectedOptions":
        setContractSection({
          ...contractSection,
          BuyerSelectedOptions: Math.abs(parseInt(value)),
        });
        break;

      case "OptionsGiven":
        setContractSection({
          ...contractSection,
          OptionsGiven: Math.abs(parseInt(value)),
        });
        break;

      case "OptionsRetailValue":
        setContractSection({
          ...contractSection,
          OptionsRetailValue: Math.abs(parseInt(value)),
        });
        break;

      case "CashOptionsPaid":
        setContractSection({
          ...contractSection,
          CashOptionsPaid: Math.abs(parseInt(value)),
        });
        break;

      case "SalesAllowanceOptions":
        setContractSection({
          ...contractSection,
          SalesAllowanceOptions: Math.abs(parseInt(value)),
        });
        break;

      case "SalesAllowanceClosingCosts":
        setContractSection({
          ...contractSection,
          SalesAllowanceClosingCosts: Math.abs(parseInt(value)),
        });
        break;

      case "SalesAllowancePriceReduced":
        setContractSection({
          ...contractSection,
          SalesAllowancePriceReduced: Math.abs(parseInt(value)),
        });
        break;

      // LaborSection
      case "LaborBudget":
        setLaborSection({
          ...laborSection,
          LaborBudget: Math.abs(parseInt(value)),
        });
        break;

      case "ExtraLaborActual":
        setLaborSection({
          ...laborSection,
          ExtraLaborActual: Math.abs(parseInt(value)),
        });
        break;

        case "SpecialLabor":
        setLaborSection({
          ...laborSection,
          SpecialLabor: Math.abs(parseInt(value)),
        });
        break;

      // MaterialSection
      case "MaterialBudget":
        setMaterialSection({
          ...materialSection,
          MaterialBudget: Math.abs(parseInt(value)),
        });
        break;

      case "ExtraMaterialBudget":
        setMaterialSection({
          ...materialSection,
          ExtraMaterialBudget: Math.abs(parseInt(value)),
        });
        break;

        case "SpecialMaterial":
          setMaterialSection({
            ...materialSection,
            SpecialMaterial: Math.abs(parseInt(value)),
          });
          break;  

      case "LotExtras":
        setLotExtraSection({
          ...lotExtraSection,
          LotExtras: Math.abs(parseInt(value)),
        });
        setRunningTotals({
          ...runningTotals,
          lotExtras: Math.abs(parseInt(value)),
        });
        break;

      case "CostOfOptionsWithContract":
        setOptionsSection({
          ...optionsSection,
          CostOfOptionsWithContract: Math.abs(parseInt(value)),
        });

        setRunningTotals({
          ...runningTotals,
          options: Math.abs(parseInt(value)),
        });
        break;

      case "LotCost":
        setSoftCostSection({
          ...softCostSection,
          LotCost: Math.abs(parseInt(value)),
        });
        break;

      case "Discretionary":
        setSoftCostSection({
          ...softCostSection,
          Discretionary: Math.abs(parseInt(value)),
        });
        break;

      case "RealtorCommissions":
        setSoftCostSection({
          ...softCostSection,
          RealtorCommissions: Math.abs(parseInt(value)),
        });
        break;

      case "SpecialMarketingIncentive":
        setSoftCostSection({
          ...softCostSection,
          SpecialMarketingIncentive: Math.abs(parseInt(value)),
        });
        break;

      case "RealtorCommissionsadjusted":
        setSoftCostSection({
          ...softCostSection,
          RealtorCommissionsadjusted: Math.abs(parseInt(value)),
        });
        break;

      case "InHouseCommissions":
        setSoftCostSection({
          ...softCostSection,
          InHouseCommissions: Math.abs(parseInt(value)),
        });
        break;

      case "InterimInterestBudget":
        setSoftCostSection({
          ...softCostSection,
          InterimInterestBudget: Math.abs(parseInt(value)),
        });
        break;

      case "ClosingCostBudget":
        setSoftCostSection({
          ...softCostSection,
          ClosingCostBudget: Math.abs(parseInt(value)),
        });
        break;

      case "BuilderBonus":
        setSoftCostSection({
          ...softCostSection,
          BuilderBonus: Math.abs(parseInt(value)),
        });
        break;

      default:
        break;
    }
    if (
      value === null ||
      value === undefined ||
      isNaN(parseInt(value === "" ? "0" : value))||
      (parseInt(value) < 0 && (field !== "LotPremium")) ||
      (parseInt(value) < -9999999.9999 && (field === "LotPremium")) ||
      parseInt(value) > 9999999.9999
    ) {
      setFormErrors((prevErrors) => ({
        ...prevErrors,
        [field]: field === "LotPremium" ? "Enter a number between -10 Million and 10 Million": "Enter a number between 0 and 10 Million",
      }));
    } else {
      setFormErrors((prevErrors) => ({
        ...prevErrors,
        [field]: "",
      }));
    }
  };


  const [exitStrategyError, setExitStrategyError] = React.useState(false);

  const [materialSection, setMaterialSection] = React.useState<{
    MaterialBudget: number | undefined;
    ExtraMaterialBudget: number | undefined;
    SpecialMaterial: number | undefined;
  }>({
    MaterialBudget: 0,
    ExtraMaterialBudget: 0,
    SpecialMaterial:0,
  });

  const [lotExtraSection, setLotExtraSection] = React.useState<{
    LotExtras: number | undefined;
  }>({
    LotExtras: 0,
  });

  const [optionsSection, setOptionsSection] = React.useState<{
    CostOfOptionsWithContract: number | undefined;
  }>({
    CostOfOptionsWithContract: 0,
  });

  const [softCostSection, setSoftCostSection] =
    React.useState<SoftCostSectionProps>({
      LotCost: 0,
      Discretionary: 0,
      HasRealtor: false,
      RealtorCommissions: 0,
      SpecialMarketingIncentive: 0,
      RealtorCommissionsadjusted: 0,
      InHouseCommissions: 0,
      InterimInterestBudget: 0,
      ClosingCostBudget: 0,
      BuilderBonus: 0,
    });

  React.useEffect(() => {
    // get a sum of all of the contractSection prices
    let sum =
      (contractSection?.BrochurePrice || 0) +
      (contractSection.LotPremium || 0) +
      (contractSection?.OptionalArchitecturalPlanChanges || 0) +
      (contractSection?.CustomArchitecturalPlanChanges || 0) +
      (contractSection?.BuyerSelectedOptions || 0) +
      (contractSection?.OptionsGiven || 0) +
      (contractSection?.OptionsRetailValue || 0) +
      (contractSection?.CashOptionsPaid || 0) -
      (contractSection?.SalesAllowanceOptions || 0) -
      (contractSection?.SalesAllowanceClosingCosts || 0) -
      (contractSection?.SalesAllowancePriceReduced || 0);

    setRunningTotals({ ...runningTotals, contract: sum });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contractSection]);

  React.useEffect(() => {
    // get a sum of all of the labor prices
    let sum =
      (laborSection?.LaborBudget || 0) + (laborSection?.ExtraLaborActual || 0) + (laborSection?.SpecialLabor || 0);

    setRunningTotals({ ...runningTotals, labor: sum });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [laborSection]);

  React.useEffect(() => {
    // get a sum of all of the material prices
    let sum =
      (materialSection?.MaterialBudget || 0) +
      (materialSection?.ExtraMaterialBudget || 0) + (materialSection?.SpecialMaterial || 0);

    setRunningTotals({ ...runningTotals, material: sum });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [materialSection]);

  React.useEffect(() => {
    // get a sum of all of the material prices
    let sum =
      (softCostSection?.LotCost || 0) +
      (softCostSection?.Discretionary || 0) +
      (softCostSection.HasRealtor
        ? softCostSection?.RealtorCommissions || 0
        : 0) +
      (softCostSection?.SpecialMarketingIncentive || 0) -
      (softCostSection?.RealtorCommissionsadjusted || 0) +
      (softCostSection?.InHouseCommissions || 0) +
      (softCostSection?.InterimInterestBudget || 0) +
      (softCostSection?.ClosingCostBudget || 0) +
      (softCostSection?.BuilderBonus || 0);

    setRunningTotals({ ...runningTotals, softCost: sum });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [softCostSection]);

  function validateState(state: any): boolean {
    for (const key in state) {
      if (state.hasOwnProperty(key)) {
        if (
          state[key] === null ||
          state[key] === undefined ||
          (key === "ProjectedCloseDate" &&
            ((typeof state[key].isValid === "function" &&
              !state[key].isValid()) ||
              state[key].year() === "NaN" ||
              state[key].year() < 1900 ||
              state[key].year() >= 2100 ||
              state[key]?.isBefore(moment(), "day")
              ))
        ) {
          return false; // Property is null or undefined
        }
      }
    }
    return true; // All properties are filled out and not null
  }

  const loadJobs = () => {
    axiosInstance
      .get(`/api/jobs/`)
      .then((res) => {
        setJobs(res.data);

        // Check if the user has correct privileges to access the PA records
        var pa = res.data.filter((p: { profitAnalysisId: number; }) => 
          p.profitAnalysisId === parseInt(id === undefined ? "" : id.toString( )));
        if (pa === null || pa.length === 0 || pa[0].role !== "Write") {
          alert("Authorization failed: You don't have the required permission!");
          navigate('/');
        }
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const handleSave = (createAnother: boolean) => {
    if (
      (!genInfo.ExitStrategy && genInfo.ExitStrategy !== 0 ) ||
      !genInfo.ProjectedCloseDate ||
      !contractSection.BrochurePrice
    ) {
      alert(
        "Exit Strategy, Projected Closed Date, and Brochure price are required"
      );
      return;
    }

    if (genInfo.ExitStrategy && genInfo.ExitStrategy < -100) {
      alert("Exit strategy cannot be less than -100%");
      return;
    }

    if (genInfo.ExitStrategy && genInfo.ExitStrategy > 100) {
      alert("Exit strategy cannot be greater than 100%");
      return;
    }

    if (!softCostSection.HasRealtor) {
      setSoftCostSection({
        ...softCostSection,
        RealtorCommissions: 0,
      });
    }

    axiosInstance
      .put(`/api/profit-analyses/${id}`, {
        ...genInfo,
        ...contractSection,
        SalesAllowanceOptions: contractSection.SalesAllowanceOptions
          ? contractSection.SalesAllowanceOptions
          : 0,
        SalesAllowanceClosingCosts: contractSection.SalesAllowanceClosingCosts
          ? contractSection.SalesAllowanceClosingCosts
          : 0,
        SalesAllowancePriceReduced: contractSection.SalesAllowancePriceReduced
          ? contractSection.SalesAllowancePriceReduced
          : 0,
        ...laborSection,
        ...materialSection,
        ...lotExtraSection,
        ...optionsSection,
        ...softCostSection,
        RealtorCommissionsadjusted: softCostSection.RealtorCommissionsadjusted
          ? softCostSection.RealtorCommissionsadjusted
          : 0,
      })
      .then((res) => {
        if (res.status === 200 && res.data === "") {
          if (!createAnother) {
            setMode("edit");
          } else {
            setMode("edit");
          }
          alert("Successfully saved updates to your profit analysis.");
        } else {
          if (res?.data?.Message !== null && res?.data?.Message !== undefined &&
            res?.data?.Message !== "") {
            alert(res.data.Message);
          } else {
            alert("There was an error creating your profit analysis");
          }
        }
      })
      .catch((err) => {
        console.error(err);
        if (err?.Message !== null && err?.Message !== undefined &&
          err?.Message !== "") {
          alert(err?.Message);
        } else {
          alert("There was an error creating your profit analysis");
        }
      });
  };

  const getDetails = (id: string) => {
    axiosInstance
      .get(`/api/profit-analyses/ProfitAnalysisByIdFromBrix/${id}`)
      .then((res) => {
        const data = res.data;
        setPdfData(data);
        SetLotPremium(data.lotPremium);
        setRunningTotals({
          contract:
            (data.brochurePrice || 0) +
            (data.lotPremium || 0) +
            (data.optionalArchitecturalPlanChanges || 0) +
            (data.customArchitecturalPlanChanges || 0) +
            (data.buyerSelectedOptions || 0) +
            (data.optionsGiven || 0) +
            (data.optionsRetailValue || 0) +
            (data.cashOptionsPaid || 0) -
            (Math.abs(data.salesAllowanceOptions) || 0) -
            (Math.abs(data.salesAllowanceClosingCosts) || 0) -
            (Math.abs(data.salesAllowancePriceReduced) || 0),
          labor: (data.laborBudget || 0) + (data.extraLaborActual || 0) + (data.specialLabor || 0),
          material:
            (data.materialBudget || 0) + (data.extraMaterialBudget || 0) + (data.specialMaterial || 0),
          lotExtras: data.lotExtras,
          options: data.costOfOptionsWithContract,
          laborMaterial: 0,
          softCost:
            (data.lotCost || 0) +
            (data.discretionary || 0) +
            (data.realtorCommissions || 0) +
            (data.specialMarketingIncentive || 0) -
            (data.realtorCommissionsadjusted || 0) +
            (data.inHouseCommissions || 0) +
            (data.interimInterestBudget || 0) +
            (data.closingCostBudget || 0) +
            (data.builderBonus || 0),
          totalPrice: 0,
          profitUSD: 0,
          profitMargin: 0,
          varianceToExitStrategy: 0,
        });
        setGenInfo({
          Subdivision: data.subdivision,
          StreetAddress: data.streetAddress,
          JobId: data.jobId,
          ProjectId: data.projectId,
          ProjectNumber: data.projectNumber,
          JobNumber: data.jobNumber,
          Buyer: data.buyer,
          PlanElevationNumber: data.planElevationNumber,
          PlanNumber: data.planNumber,
          ProfitAnalysisType: data.profitAnalysisType,
          ExitStrategy: data.exitStrategy,
          DateSold: moment(data.dateSold) as any,
          ProjectedCloseDate: moment(data.projectedCloseDate).startOf('day') as any,
          //@ts-ignore
          CurrentUser: account.name as string,
          SalesPrice: data.salesPrice,
        });
        setContractSection({
          BrochurePrice: data.brochurePrice,
          LotPremium: data.lotPremium,
          OptionalArchitecturalPlanChanges:
            data.optionalArchitecturalPlanChanges,
          CustomArchitecturalPlanChanges: data.customArchitecturalPlanChanges,
          BuyerSelectedOptions: data.buyerSelectedOptions,
          OptionsGiven: data.optionsGiven,
          OptionsRetailValue: data.optionsRetailValue,
          CashOptionsPaid: data.cashOptionsPaid,
          SalesAllowanceOptions: Math.abs(data.salesAllowanceOptions),
          SalesAllowanceClosingCosts: Math.abs(data.salesAllowanceClosingCosts),
          SalesAllowancePriceReduced: Math.abs(data.salesAllowancePriceReduced),
        });
        setLaborSection({
          LaborBudget: data.laborBudget,
          ExtraLaborActual: data.extraLaborActual,
          SpecialLabor:data.specialLabor,
        });
        setMaterialSection({
          MaterialBudget: data.materialBudget,
          ExtraMaterialBudget: data.extraMaterialBudget,
          SpecialMaterial:data.specialMaterial,
        });
        setLotExtraSection({
          LotExtras: data.lotExtras,
        });
        setOptionsSection({
          CostOfOptionsWithContract: data.costOfOptionsWithContract,
        });
        setSoftCostSection({
          LotCost: data.lotCost,
          Discretionary: data.discretionary,
          HasRealtor: data.hasRealtor,
          RealtorCommissions: data.realtorCommissions,
          SpecialMarketingIncentive: data.specialMarketingIncentive,
          RealtorCommissionsadjusted: Math.abs(data.realtorCommissionsadjusted),
          InHouseCommissions: data.inHouseCommissions,
          InterimInterestBudget: data.interimInterestBudget,
          ClosingCostBudget: data.closingCostBudget,
          BuilderBonus: data.builderBonus,
        });
      })
      .catch((err) => {
        console.error(err);
      });
  };

  React.useEffect(() => {
    if (id) {
      getDetails(id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  React.useEffect(() => {
    loadJobs();
  }, []);

  function percentage(partialValue: number, totalValue: number) {
    return (partialValue / totalValue) * 100;
  }

  const subtotalLaborMaterial =
    (runningTotals.labor || 0) +
    (runningTotals.material || 0) +
    (runningTotals.lotExtras || 0);

  const totalLaborMaterial =
    (subtotalLaborMaterial || 0) + (runningTotals.options || 0);

  const totalCost = (totalLaborMaterial || 0) + (runningTotals.softCost || 0);

  const profitUSD = (runningTotals.contract || 0) - (totalCost || 0);

  const profitMargin = percentage(profitUSD, runningTotals.contract);
  const exitStrategy=genInfo.ExitStrategy === undefined ? 0 : genInfo.ExitStrategy;
  const varianceToExitStrategy= (profitMargin - exitStrategy);

  const ableToCreate = validateState(genInfo);
  //check if exit strategy is greater 0

  const [openConfirmation, setOpenConfirmation] = useState(false);

  const handleSubmitConfirm = () => {
    handleSave(false);
    setOpenConfirmation(false);
  };
  
  const checkFormValidity = () => {
    // Check if any field has an error
    const hasError = Object.values(formErrors).some((error) => error !== "");
    return !hasError && ableToCreate && !exitStrategyError;
  };

  return (
    <ThemeProvider theme={theme}>
      <LocalizationProvider dateAdapter={AdapterMoment}>
        <Container component="main" maxWidth={false}>
          <CssBaseline />
          <Box sx={{ display: "flex" }}>
            <CssBaseline />
            <Header />
            <Drawer
              variant="permanent"
              sx={{
                width: drawerWidth,
                flexShrink: 0,
                [`& .MuiDrawer-paper`]: {
                  width: drawerWidth,
                  boxSizing: "border-box",
                },
              }}
            >
              <Toolbar sx={{ backgroundColor: "red" }} />

              <Box
                sx={{
                  overflow: "auto",
                  backgroundColor: "rgba(183, 176, 255, 0.13)",
                  flex: 1,
                }}
              >
                <Stack direction="column" spacing={5} mt={2}>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "flex-end",
                      padding: 2,
                      flex: 1,
                    }}
                  >
                    <Typography
                      variant="h5"
                      fontWeight="bold"
                      align="right"
                      sx={{
                        color: "#25D72C",
                      }}
                    >
                      {`$${numberWithCommas(
                        runningTotals?.contract.toFixed(2) || 0
                      )}`}
                    </Typography>
                    <Typography variant="h6" color="text.secondary">
                      Contract Price
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "flex-end",
                      padding: 2,
                      flex: 1,
                    }}
                  >
                    <Typography
                      variant="h5"
                      fontWeight="bold"
                      align="right"
                      sx={{
                        color: "#25D72C",
                      }}
                    >
                      {`$${numberWithCommas(totalCost.toFixed(2) || 0)}`}
                    </Typography>
                    <Typography variant="h6" color="text.secondary">
                      Total Cost
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "flex-end",
                      padding: 2,
                      flex: 1,
                    }}
                  >
                    <Typography
                      variant="h5"
                      fontWeight="bold"
                      align="right"
                      sx={{
                        color: "#25D72C",
                      }}
                    >
                      {`$${numberWithCommas(profitUSD.toFixed(2) || 0)}`}
                    </Typography>
                    <Typography variant="h6" color="text.secondary">
                      Profit USD
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "flex-end",
                      padding: 2,
                      flex: 1,
                    }}
                  >
                    <Typography
                      variant="h5"
                      fontWeight="bold"
                      align="right"
                      sx={{
                        color: "#25D72C",
                      }}
                    >
                      {runningTotals.contract === 0
                        ? "N/A"
                        : (profitMargin.toFixed(2) || 0) + "%"}
                    </Typography>
                    <Typography variant="h6" color="text.secondary">
                      Profit Margin
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "flex-end",
                      padding: 2,
                      flex: 1,
                    }}
                  >
                    <Typography
                      variant="h5"
                      fontWeight="bold"
                      align="right"
                      sx={{
                        color:exitStrategy<=profitMargin? "#25D72C":"red",
                      }}
                    >
                      {varianceToExitStrategy.toFixed(2) || 0}%
                    </Typography>
                    <Typography variant="h6" color="text.secondary">
                      Variance To Exit Strategy
                    </Typography>
                  </Box>
                </Stack>
              </Box>
            </Drawer>
            <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
              <Toolbar />
              {mode === "review" && (
                <Stack
                  direction="row"
                  justifyContent="space-between"
                  spacing={2}
                  my={5}
                >
                  <Box>
                    <Button variant="contained" onClick={() => setMode("edit")}>
                      EDIT
                    </Button>
                  </Box>
                  <Box>
                    <Button
                      onClick={() => {
                        setOpenConfirmation(true);
                      }}
                      disabled={!ableToCreate}
                      variant="contained"
                      sx={{
                        maxWidth: 300,
                        mr: 2,
                      }}
                    >
                      Save Changes
                    </Button>

                    <ConfirmationDialog
                      open={openConfirmation}
                      onClose={() => setOpenConfirmation(false)}
                      onConfirm={handleSubmitConfirm}
                      title="Edit | Confirmation"
                      content="Are you sure you want to confirm these edits?"
                    />
                    {/* <Button
                    onClick={() => {
                      handleSave(true);
                    }}
                    disabled={!ableToCreate}
                    variant="contained"
                    sx={{
                      maxWidth: 300,
                    }}
                  >
                    Save and create another
                  </Button> */}
                  </Box>
                </Stack>
              )}

              {mode === "edit" && (
                <Stack
                  direction="row"
                  justifyContent="flex-end"
                  my={5}
                  spacing={1}
                >
                  {pdfData && (
                    <Button
                      variant="contained"
                      onClick={() =>
                        generatePDF(
                          pdfData,
                          genInfo,
                          contractSection,
                          laborSection,
                          materialSection,
                          lotExtraSection,
                          optionsSection,
                          softCostSection,
                          runningTotals
                        )
                      }
                    >
                      Print
                    </Button>
                  )}
                  <Button
                    variant="contained"
                    onClick={() => {
                      if (ableToCreate) {
                        setMode("review");
                      } else {
                        alert("Please fill out the general information");
                      }
                    }}
                    sx={{
                      maxWidth: 300,
                    }}
                    disabled={!checkFormValidity()}
                  >
                    Review
                  </Button>
                </Stack>
              )}
              <Grid container spacing={2} my={5}>
                <Grid item xs={6}>
                  <TextField
                    value={genInfo.Subdivision}
                    onChange={(e) =>
                      setGenInfo({ ...genInfo, Subdivision: e.target.value })
                    }
                    id="outlined-basic"
                    label="Subdivision*"
                    variant="filled"
                    disabled
                    fullWidth
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    value={genInfo.StreetAddress}
                    onChange={(e) =>
                      setGenInfo({ ...genInfo, StreetAddress: e.target.value })
                    }
                    id="outlined-basic"
                    label="Address*"
                    variant="filled"
                    disabled
                    fullWidth
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    value={genInfo.JobNumber}
                    onChange={(e) =>
                      setGenInfo({ ...genInfo, JobNumber: e.target.value })
                    }
                    id="outlined-basic"
                    label="Job Number"
                    variant="filled"
                    disabled
                    fullWidth
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    value={genInfo.ProjectNumber}
                    onChange={(e) =>
                      setGenInfo({ ...genInfo, ProjectNumber: e.target.value })
                    }
                    id="outlined-basic"
                    label="Project Number"
                    variant="filled"
                    disabled
                    fullWidth
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    value={genInfo.Buyer}
                    onChange={(e) =>
                      setGenInfo({ ...genInfo, Buyer: e.target.value })
                    }
                    id="outlined-basic"
                    label="Buyer"
                    variant="filled"
                    disabled
                    fullWidth
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    value={genInfo.PlanNumber}
                    onChange={(e) =>
                      setGenInfo({ ...genInfo, PlanNumber: e.target.value })
                    }
                    id="outlined-basic"
                    label="Plan"
                    variant="filled"
                    disabled
                    sx={{ width: "48%" }}
                  />
                  <TextField
                    value={genInfo.PlanElevationNumber}
                    onChange={(e) =>
                      setGenInfo({
                        ...genInfo,
                        PlanElevationNumber: e.target.value,
                      })
                    }
                    id="outlined-basic"
                    label="Elevation"
                    variant="filled"
                    disabled
                    sx={{ width: "48%", marginLeft: 1 }}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    value={genInfo.ProfitAnalysisType}
                    onChange={(e) =>
                      setGenInfo({
                        ...genInfo,
                        ProfitAnalysisType: e.target.value,
                      })
                    }
                    id="outlined-basic"
                    label="PA Type"
                    variant="filled"
                    disabled
                    fullWidth
                  />
                </Grid>
                <Grid item xs={6}>
                <Tooltip title="Exit Strategy value must be between -100 and 100." arrow>
                    <TextField
                      value={genInfo.ExitStrategy}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment
                            position="start"
                            sx={{
                              ml: 1,
                            }}
                          >
                            %
                          </InputAdornment>
                        ),
                      }}
                      // onChange={(e) =>
                      //   setGenInfo({
                      //     ...genInfo,
                      //     ExitStrategy: Math.abs(parseInt(e.target.value)),
                      //   })
                      // }
                      onChange={(e) => {

                        // regex that allow -100, 100 and any 1 or 2 digit number .. positive or negative
                        const regex = /^-?(?:100|-100|\d{1,2})$/;
                        setGenInfo({
                          ...genInfo,
                          ExitStrategy: parseInt(e.target.value),
                        });

                        if (
                          e.target.value === null ||
                          e.target.value === undefined ||
                          e.target.value === "" ||
                          isNaN(parseInt(e.target.value)) ||
                          parseInt(e.target.value) > 100 ||
                          parseInt(e.target.value) < -100 ||                          
                          !(regex.test(e.target.value) || e.target.value === '-')
                        ) {
                          setExitStrategyError(true);
                        } else {

                          setExitStrategyError(false);
                        }
                      }}
                      error={exitStrategyError}
                      helperText={
                        exitStrategyError
                          ? "Exit Strategy must be within the range of -100 to 100."
                          : ""
                      }                      
                      type="number"
                      id="outlined-basic"
                      label="Exit Strategy*"
                      disabled={mode === "review"}
                      // variant={mode === "review" ? "filled" : "outlined"}
                      fullWidth
                    />
                  </Tooltip>
                </Grid>

                <Grid item xs={6}>
                  <DatePicker
                    value={genInfo.DateSold}
                    onChange={(newValue) =>
                      setGenInfo({ ...genInfo, DateSold: newValue })
                    }
                    label="Date Sold"
                    sx={{
                      width: "100%",
                    }}
                    disabled
                    slotProps={{ textField: { variant: "filled" } }}
                  />
                </Grid>
                <Grid item xs={6}>
                  <DatePicker
                    minDate={minDate}
                    disabled={mode === "review"}
                    value={genInfo.ProjectedCloseDate}
                    onChange={(newValue: any) => {
                      // Check if the date is in the past or invalid
                      if (
                        (newValue && !newValue.isValid()) ||
                        (newValue && newValue.isBefore(moment(), "day"))
                      ) {
                        // Show error or reset to default value (e.g., today's date)
                        setGenInfo({
                          ...genInfo,
                          ProjectedCloseDate: moment().startOf('day'),
                        });
                        // Optionally, use a state variable to show an error message
                      } else {
                        setGenInfo({
                          ...genInfo,
                          ProjectedCloseDate: moment(newValue).startOf('day'),
                        });
                      }
                    }}
                    slotProps={{
                      textField: {
                        // variant: mode === "review" ? "filled" : "outlined",
                      },
                    }}
                    label="Projected Closed Date*"
                    sx={{
                      width: "100%",
                    }}
                  />
                </Grid>
              </Grid>
              <Box pt={8} id="contract">
                <Accordion elevation={0} expanded={true}>
                  <AccordionSummary>
                    <Box
                      sx={{
                        display: "flex",
                        flex: 1,
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <Stack direction="row" spacing={2}>
                        <Icon fontSize="large">
                          <TextSnippetIcon
                            fontSize="large"
                            sx={{
                              color: "#ACACAC",
                            }}
                          />
                        </Icon>
                        <Typography
                          fontSize={20}
                          sx={{
                            alignSelf: "end",
                          }}
                        >
                          Contract
                        </Typography>
                      </Stack>
                      <Typography
                        fontSize={24}
                        sx={{
                          alignSelf: "end",
                          color: "#25D72C",
                          fontWeight: "bold",
                        }}
                      >
                        {`$${numberWithCommas(runningTotals?.contract || 0)}`}
                      </Typography>
                    </Box>
                  </AccordionSummary>
                </Accordion>
                <Divider />
                <Grid container mt={4} spacing={2}>
                  <Grid item xs={9} display="flex">
                    <Typography
                      variant="body1"
                      fontWeight="bold"
                      sx={{
                        alignSelf: "center",
                      }}
                    >
                      Brochure Price*
                    </Typography>
                  </Grid>
                  <Grid item xs={3}>
                    <TextField
                      inputProps={{
                        min: 0,
                        max: 9999999.9999,
                      }}
                      type="number"
                      disabled={mode === "review"}
                      value={
                        contractSection.BrochurePrice === undefined
                          ? ""
                          : contractSection.BrochurePrice.toString().replace(/^0+/, '')
                      }
                      onChange={(e) =>
                        updateField("BrochurePrice", e.target.value)
                      }
                      error={formErrors["BrochurePrice"] !== ""}
                      helperText={formErrors["BrochurePrice"]}
                      variant="outlined"
                      size="small"
                      fullWidth
                      InputProps={{
                        startAdornment: (
                          <InputAdornment
                            position="start"
                            sx={{
                              ml: 1,
                            }}
                          >
                            $
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Grid>
                  <Grid item xs={9} display="flex">
                    <Typography
                      variant="body1"
                      fontWeight="bold"
                      sx={{
                        alignSelf: "center",
                      }}
                    >
                      Lot Premium Price
                    </Typography>
                  </Grid>
                  <Grid item xs={3}>
                    <TextField
                      disabled={mode === "review"}
                      type="number"
                      value={
                        contractSection.LotPremium === undefined
                          ? "" : lotPremium
                      }
                      onChange={(e) => {
                        updateField("LotPremium", e.target.value);
                      }
                      }
                      error={formErrors["LotPremium"] !== ""}
                      helperText={formErrors["LotPremium"]}
                      variant="outlined"
                      size="small"
                      fullWidth
                      InputProps={{
                        
                        startAdornment: (
                          <InputAdornment
                            position="start"
                            sx={{
                              ml: 1,
                            }}
                          >
                            $
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Grid>
                  <Grid item xs={9} display="flex">
                    <Typography
                      variant="body1"
                      fontWeight="bold"
                      sx={{
                        alignSelf: "center",
                      }}
                    >
                      Optional Architectural Plan Changes
                    </Typography>
                  </Grid>
                  <Grid item xs={3}>
                    <TextField
                      disabled={mode === "review"}
                      type="number"
                      inputProps={{
                        min: 0,
                      }}
                      value={
                        contractSection.OptionalArchitecturalPlanChanges ===
                        undefined
                          ? ""
                          : contractSection.OptionalArchitecturalPlanChanges.toString().replace(/^0+/, '')
                      }
                      onChange={(e) =>
                        updateField(
                          "OptionalArchitecturalPlanChanges",
                          e.target.value
                        )
                      }
                      error={
                        formErrors["OptionalArchitecturalPlanChanges"] !== ""
                      }
                      helperText={
                        formErrors["OptionalArchitecturalPlanChanges"]
                      }
                      variant="outlined"
                      size="small"
                      fullWidth
                      InputProps={{
                        startAdornment: (
                          <InputAdornment
                            position="start"
                            sx={{
                              ml: 1,
                            }}
                          >
                            $
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Grid>
                  <Grid item xs={9} display="flex">
                    <Typography
                      variant="body1"
                      fontWeight="bold"
                      sx={{
                        alignSelf: "center",
                      }}
                    >
                      Custom Architectural Plan Changes
                    </Typography>
                  </Grid>
                  <Grid item xs={3}>
                    <TextField
                      disabled={mode === "review"}
                      type="number"
                      inputProps={{
                        min: 0,
                      }}
                      value={
                        contractSection.CustomArchitecturalPlanChanges ===
                        undefined
                          ? ""
                          : contractSection.CustomArchitecturalPlanChanges.toString().replace(/^0+/, '')
                      }
                      onChange={(e) =>
                        updateField(
                          "CustomArchitecturalPlanChanges",
                          e.target.value
                        )
                      }
                      error={
                        formErrors["CustomArchitecturalPlanChanges"] !== ""
                      }
                      helperText={formErrors["CustomArchitecturalPlanChanges"]}
                      variant="outlined"
                      size="small"
                      fullWidth
                      InputProps={{
                        startAdornment: (
                          <InputAdornment
                            position="start"
                            sx={{
                              ml: 1,
                            }}
                          >
                            $
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Grid>
                  <Grid item xs={9} display="flex">
                    <Typography
                      variant="body1"
                      fontWeight="bold"
                      sx={{
                        alignSelf: "center",
                      }}
                    >
                      Buyer Selected Options/Spec Options
                    </Typography>
                  </Grid>
                  <Grid item xs={3}>
                    <TextField
                      disabled={mode === "review"}
                      type="number"
                      inputProps={{
                        min: 0,
                      }}
                      value={
                        contractSection.BuyerSelectedOptions === undefined
                          ? ""
                          : contractSection.BuyerSelectedOptions.toString().replace(/^0+/, '')
                      }
                      onChange={(e) =>
                        updateField("BuyerSelectedOptions", e.target.value)
                      }
                      error={formErrors["BuyerSelectedOptions"] !== ""}
                      helperText={formErrors["BuyerSelectedOptions"]}
                      variant="outlined"
                      size="small"
                      fullWidth
                      InputProps={{
                        startAdornment: (
                          <InputAdornment
                            position="start"
                            sx={{
                              ml: 1,
                            }}
                          >
                            $
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Grid>
                  <Grid item xs={9} display="flex">
                    <Typography
                      variant="body1"
                      fontWeight="bold"
                      sx={{
                        alignSelf: "center",
                      }}
                    >
                      Options Given TBD/Seller Paid Closing Costs
                    </Typography>
                  </Grid>
                  <Grid item xs={3}>
                    <TextField
                      disabled={mode === "review"}
                      type="number"
                      inputProps={{
                        min: 0,
                      }}
                      value={
                        contractSection.OptionsGiven === undefined
                          ? ""
                          : contractSection.OptionsGiven.toString().replace(/^0+/, '')
                      }
                      onChange={(e) =>
                        updateField("OptionsGiven", e.target.value)
                      }
                      error={formErrors["OptionsGiven"] !== ""}
                      helperText={formErrors["OptionsGiven"]}
                      variant="outlined"
                      size="small"
                      fullWidth
                      InputProps={{
                        startAdornment: (
                          <InputAdornment
                            position="start"
                            sx={{
                              ml: 1,
                            }}
                          >
                            $
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Grid>
                  <Grid item xs={9} display="flex">
                    <Typography
                      variant="body1"
                      fontWeight="bold"
                      sx={{
                        alignSelf: "center",
                      }}
                    >
                      Options Retail Value @ N/C - (Incl Spec Opts)
                    </Typography>
                  </Grid>
                  <Grid item xs={3}>
                    <TextField
                      disabled={mode === "review"}
                      type="number"
                      inputProps={{
                        min: 0,
                      }}
                      value={
                        contractSection.OptionsRetailValue === undefined
                          ? ""
                          : contractSection.OptionsRetailValue.toString().replace(/^0+/, '')
                      }
                      onChange={(e) =>
                        updateField("OptionsRetailValue", e.target.value)
                      }
                      error={formErrors["OptionsRetailValue"] !== ""}
                      helperText={formErrors["OptionsRetailValue"]}
                      variant="outlined"
                      size="small"
                      fullWidth
                      InputProps={{
                        startAdornment: (
                          <InputAdornment
                            position="start"
                            sx={{
                              ml: 1,
                            }}
                          >
                            $
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Grid>
                  <Grid item xs={9} display="flex">
                    <Typography
                      variant="body1"
                      fontWeight="bold"
                      sx={{
                        alignSelf: "center",
                      }}
                    >
                      Cash Options Paid
                    </Typography>
                  </Grid>
                  <Grid item xs={3}>
                    <TextField
                      disabled={mode === "review"}
                      type="number"
                      inputProps={{
                        min: 0,
                      }}
                      value={
                        contractSection.CashOptionsPaid === undefined
                          ? ""
                          : contractSection.CashOptionsPaid.toString().replace(/^0+/, '')
                      }
                      onChange={(e) =>
                        updateField("CashOptionsPaid", e.target.value)
                      }
                      error={formErrors["CashOptionsPaid"] !== ""}
                      helperText={formErrors["CashOptionsPaid"]}
                      variant="outlined"
                      size="small"
                      fullWidth
                      InputProps={{
                        startAdornment: (
                          <InputAdornment
                            position="start"
                            sx={{
                              ml: 1,
                            }}
                          >
                            $
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Grid>
                  <Grid item xs={9} display="flex">
                    <Typography
                      variant="body1"
                      fontWeight="bold"
                      sx={{
                        alignSelf: "center",
                      }}
                    >
                      Sales Allowance Options
                    </Typography>
                  </Grid>
                  <Grid item xs={3}>
                    <TextField
                      disabled={mode === "review"}
                      type="number"
                      inputProps={{
                        min: 0,
                      }}
                      value={
                        contractSection.SalesAllowanceOptions === undefined
                          ? ""
                          : contractSection.SalesAllowanceOptions.toString().replace(/^0+/, '')
                      }
                      onChange={(e) =>
                        updateField("SalesAllowanceOptions", e.target.value)
                      }
                      error={formErrors["SalesAllowanceOptions"] !== ""}
                      helperText={formErrors["SalesAllowanceOptions"]}
                      variant="outlined"
                      size="small"
                      fullWidth
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">-$</InputAdornment>
                        ),
                      }}
                    />
                  </Grid>
                  <Grid item xs={9} display="flex">
                    <Typography
                      variant="body1"
                      fontWeight="bold"
                      sx={{
                        alignSelf: "center",
                      }}
                    >
                      Sales Allowance Closing Costs
                    </Typography>
                  </Grid>
                  <Grid item xs={3}>
                    <TextField
                      disabled={mode === "review"}
                      type="number"
                      inputProps={{
                        min: 0,
                      }}
                      value={
                        contractSection.SalesAllowanceClosingCosts === undefined
                          ? ""
                          : contractSection.SalesAllowanceClosingCosts.toString().replace(/^0+/, '')
                      }
                      onChange={(e) =>
                        updateField(
                          "SalesAllowanceClosingCosts",
                          e.target.value
                        )
                      }
                      error={formErrors["SalesAllowanceClosingCosts"] !== ""}
                      helperText={formErrors["SalesAllowanceClosingCosts"]}
                      variant="outlined"
                      size="small"
                      fullWidth
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">-$</InputAdornment>
                        ),
                      }}
                    />
                  </Grid>
                  <Grid item xs={9} display="flex">
                    <Typography
                      variant="body1"
                      fontWeight="bold"
                      sx={{
                        alignSelf: "center",
                      }}
                    >
                      Sales Allowance Price Reduced
                    </Typography>
                  </Grid>
                  <Grid item xs={3}>
                    <TextField
                      disabled={mode === "review"}
                      type="number"
                      inputProps={{
                        min: 0,
                      }}
                      value={
                        contractSection.SalesAllowancePriceReduced === undefined
                          ? ""
                          : contractSection.SalesAllowancePriceReduced.toString().replace(/^0+/, '')
                      }
                      onChange={(e) =>
                        updateField(
                          "SalesAllowancePriceReduced",
                          e.target.value
                        )
                      }
                      error={formErrors["SalesAllowancePriceReduced"] !== ""}
                      helperText={formErrors["SalesAllowancePriceReduced"]}
                      variant="outlined"
                      size="small"
                      fullWidth
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">-$</InputAdornment>
                        ),
                      }}
                    />
                  </Grid>
                  <Grid
                    item
                    xs={9}
                    display="flex"
                    justifyContent="flex-end"
                    mt={5}
                  >
                    <Typography
                      variant="body1"
                      fontWeight="bold"
                      sx={{
                        alignSelf: "center",
                      }}
                    >
                      Contract Price
                    </Typography>
                  </Grid>
                  <Grid item xs={3} mt={5}>
                    <TextField
                      type="number"
                      inputProps={{
                        min: 0,
                      }}
                      disabled
                      value={runningTotals.contract}
                      variant="filled"
                      size="small"
                      fullWidth
                      InputProps={{
                        startAdornment: (
                          <InputAdornment
                            position="start"
                            sx={{
                              ml: 1,
                            }}
                          >
                            $
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Grid>
                </Grid>
              </Box>
              <Box pt={8} id="labor">
                <Accordion elevation={0} expanded={true}>
                  <AccordionSummary>
                    <Box
                      sx={{
                        display: "flex",
                        flex: 1,
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <Stack direction="row" spacing={2}>
                        <Icon fontSize="large">
                          <ConstructionIcon
                            fontSize="large"
                            sx={{
                              color: "#ACACAC",
                            }}
                          />
                        </Icon>
                        <Typography
                          fontSize={20}
                          sx={{
                            alignSelf: "end",
                          }}
                        >
                          Labor
                        </Typography>
                      </Stack>
                      <Typography
                        fontSize={24}
                        sx={{
                          alignSelf: "end",
                          color: "#25D72C",
                          fontWeight: "bold",
                        }}
                      >
                        {`$${numberWithCommas(runningTotals?.labor || 0)}`}
                      </Typography>
                    </Box>
                  </AccordionSummary>
                </Accordion>
                <Divider />
                <Grid container mt={4} spacing={2}>
                  <Grid item xs={9} display="flex">
                    <Typography
                      variant="body1"
                      fontWeight="bold"
                      sx={{
                        alignSelf: "center",
                      }}
                    >
                      Labor Budget/Category A
                    </Typography>
                  </Grid>
                  <Grid item xs={3}>
                    <TextField
                      disabled={mode === "review"}
                      variant="outlined"
                      size="small"
                      type="number"
                      inputProps={{
                        min: 0,
                      }}
                      value={
                        laborSection.LaborBudget === undefined
                          ? ""
                          : laborSection.LaborBudget.toString().replace(/^0+/, '')
                      }
                      onChange={(e) =>
                        updateField("LaborBudget", e.target.value)
                      }
                      error={formErrors["LaborBudget"] !== ""}
                      helperText={formErrors["LaborBudget"]}
                      fullWidth
                      InputProps={{
                        startAdornment: (
                          <InputAdornment
                            position="start"
                            sx={{
                              ml: 1,
                            }}
                          >
                            $
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Grid>
                  <Grid item xs={9} display="flex">
                    <Typography
                      variant="body1"
                      fontWeight="bold"
                      sx={{
                        alignSelf: "center",
                      }}
                    >
                      Extra Labor Actual/Category B
                    </Typography>
                  </Grid>
                  <Grid item xs={3}>
                    <TextField
                      disabled={mode === "review"}
                      type="number"
                      inputProps={{
                        min: 0,
                      }}
                      variant="outlined"
                      size="small"
                      value={
                        laborSection.ExtraLaborActual === undefined
                          ? ""
                          : laborSection.ExtraLaborActual.toString().replace(/^0+/, '')
                      }
                      onChange={(e) =>
                        updateField("ExtraLaborActual", e.target.value)
                      }
                      error={formErrors["ExtraLaborActual"] !== ""}
                      helperText={formErrors["ExtraLaborActual"]}
                      fullWidth
                      InputProps={{
                        startAdornment: (
                          <InputAdornment
                            position="start"
                            sx={{
                              ml: 1,
                            }}
                          >
                            $
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Grid>
                  <Grid item xs={9} display="flex">
                    <Typography
                      variant="body1"
                      fontWeight="bold"
                      sx={{
                        alignSelf: "center",
                      }}
                    >
                     Special Labor Budget
                    </Typography>
                  </Grid>
                  <Grid item xs={3}>
                    <TextField
                      disabled={mode === "review"}
                      type="number"
                      inputProps={{
                        min: 0,
                      }}
                      variant="outlined"
                      size="small"
                      value={
                        laborSection.SpecialLabor === undefined
                          ? ""
                          : laborSection.SpecialLabor.toString().replace(/^0+/, '')
                      }
                      onChange={(e) =>
                        updateField("SpecialLabor", e.target.value)
                      }
                      error={formErrors["SpecialLabor"] !== ""}
                      helperText={formErrors["SpecialLabor"]}
                      fullWidth
                      InputProps={{
                        startAdornment: (
                          <InputAdornment
                            position="start"
                            sx={{
                              ml: 1,
                            }}
                          >
                            $
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Grid>
                  <Grid
                    item
                    xs={9}
                    display="flex"
                    justifyContent="flex-end"
                    mt={5}
                  >
                    <Typography
                      variant="body1"
                      fontWeight="bold"
                      sx={{
                        alignSelf: "center",
                      }}
                    >
                      Total Labor
                    </Typography>
                  </Grid>
                  <Grid item xs={3} mt={5}>
                    <TextField
                      type="number"
                      inputProps={{
                        min: 0,
                      }}
                      disabled
                      variant="filled"
                      size="small"
                      fullWidth
                      value={runningTotals.labor}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment
                            position="start"
                            sx={{
                              ml: 1,
                            }}
                          >
                            $
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Grid>
                </Grid>
              </Box>
              <Box pt={8} id="material">
                <Accordion elevation={0} expanded={true}>
                  <AccordionSummary>
                    <Box
                      sx={{
                        display: "flex",
                        flex: 1,
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <Stack direction="row" spacing={2}>
                        <Icon fontSize="large">
                          <CabinIcon
                            fontSize="large"
                            sx={{
                              color: "#ACACAC",
                            }}
                          />
                        </Icon>
                        <Typography
                          fontSize={20}
                          sx={{
                            alignSelf: "end",
                          }}
                        >
                          Material
                        </Typography>
                      </Stack>
                      <Typography
                        fontSize={24}
                        sx={{
                          alignSelf: "end",
                          color: "#25D72C",
                          fontWeight: "bold",
                        }}
                      >
                        {`$${numberWithCommas(runningTotals?.material)}`}
                      </Typography>
                    </Box>
                  </AccordionSummary>
                </Accordion>
                <Divider />
                <Grid container mt={4} spacing={2}>
                  <Grid item xs={9} display="flex">
                    <Typography
                      variant="body1"
                      fontWeight="bold"
                      sx={{
                        alignSelf: "center",
                      }}
                    >
                      Material Budget/Category C
                    </Typography>
                  </Grid>
                  <Grid item xs={3}>
                    <TextField
                      disabled={mode === "review"}
                      type="number"
                      inputProps={{
                        min: 0,
                      }}
                      variant="outlined"
                      size="small"
                      fullWidth
                      value={
                        materialSection.MaterialBudget === undefined
                          ? ""
                          : materialSection.MaterialBudget.toString().replace(/^0+/, '')
                      }
                      onChange={(e) =>
                        updateField("MaterialBudget", e.target.value)
                      }
                      error={formErrors["MaterialBudget"] !== ""}
                      helperText={formErrors["MaterialBudget"]}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment
                            position="start"
                            sx={{
                              ml: 1,
                            }}
                          >
                            $
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Grid>
                  <Grid item xs={9} display="flex">
                    <Typography
                      variant="body1"
                      fontWeight="bold"
                      sx={{
                        alignSelf: "center",
                      }}
                    >
                      Extra Material Budget/Category D
                    </Typography>
                  </Grid>
                  <Grid item xs={3}>
                    <TextField
                      type="number"
                      disabled={mode === "review"}
                      inputProps={{
                        min: 0,
                      }}
                      variant="outlined"
                      size="small"
                      fullWidth
                      value={
                        materialSection.ExtraMaterialBudget === undefined
                          ? ""
                          : materialSection.ExtraMaterialBudget.toString().replace(/^0+/, '')
                      }
                      onChange={(e) =>
                        updateField("ExtraMaterialBudget", e.target.value)
                      }
                      error={formErrors["ExtraMaterialBudget"] !== ""}
                      helperText={formErrors["ExtraMaterialBudget"]}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment
                            position="start"
                            sx={{
                              ml: 1,
                            }}
                          >
                            $
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Grid>
                  <Grid item xs={9} display="flex">
                    <Typography
                      variant="body1"
                      fontWeight="bold"
                      sx={{
                        alignSelf: "center",
                      }}
                    >
                      Special Material Budget
                    </Typography>
                  </Grid>
                  <Grid item xs={3}>
                    <TextField
                      type="number"
                      disabled={mode === "review"}
                      inputProps={{
                        min: 0,
                      }}
                      variant="outlined"
                      size="small"
                      fullWidth
                      value={
                        materialSection.SpecialMaterial === undefined
                          ? ""
                          : materialSection.SpecialMaterial.toString().replace(/^0+/, '')
                      }
                      onChange={(e) =>
                        updateField("SpecialMaterial", e.target.value)
                      }
                      error={formErrors["SpecialMaterial"] !== ""}
                      helperText={formErrors["SpecialMaterial"]}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment
                            position="start"
                            sx={{
                              ml: 1,
                            }}
                          >
                            $
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Grid>
                  <Grid
                    item
                    xs={9}
                    display="flex"
                    justifyContent="flex-end"
                    mt={5}
                  >
                    <Typography
                      variant="body1"
                      fontWeight="bold"
                      sx={{
                        alignSelf: "center",
                      }}
                    >
                      Total Material
                    </Typography>
                  </Grid>
                  <Grid item xs={3} mt={5}>
                    <TextField
                      type="number"
                      inputProps={{
                        min: 0,
                      }}
                      disabled
                      variant="filled"
                      size="small"
                      fullWidth
                      value={runningTotals.material}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment
                            position="start"
                            sx={{
                              ml: 1,
                            }}
                          >
                            $
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Grid>
                </Grid>
              </Box>
              <Box pt={8} id="lotExtras">
                <Accordion elevation={0} expanded={true}>
                  <AccordionSummary>
                    <Box
                      sx={{
                        display: "flex",
                        flex: 1,
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <Stack direction="row" spacing={2}>
                        <Icon fontSize="large">
                          <LandscapeIcon
                            fontSize="large"
                            sx={{
                              color: "#ACACAC",
                            }}
                          />
                        </Icon>
                        <Typography
                          fontSize={20}
                          sx={{
                            alignSelf: "end",
                          }}
                        >
                          Lot Extras
                        </Typography>
                      </Stack>
                      <Typography
                        fontSize={24}
                        sx={{
                          alignSelf: "end",
                          color: "#25D72C",
                          fontWeight: "bold",
                        }}
                      >
                        {`$${numberWithCommas(runningTotals?.lotExtras || 0)}`}
                      </Typography>
                    </Box>
                  </AccordionSummary>
                </Accordion>
                <Divider />
                <Grid container mt={4} spacing={2}>
                  <Grid item xs={9} display="flex">
                    <Typography
                      variant="body1"
                      fontWeight="bold"
                      sx={{
                        alignSelf: "center",
                      }}
                    >
                      Lot Extras(Exit Strategy)
                    </Typography>
                  </Grid>
                  <Grid item xs={3}>
                    <TextField
                      disabled={mode === "review"}
                      variant="outlined"
                      size="small"
                      type="number"
                      inputProps={{
                        min: 0,
                      }}
                      value={
                        lotExtraSection.LotExtras === undefined
                          ? ""
                          : lotExtraSection.LotExtras.toString().replace(/^0+/, '')
                      }
                      onChange={(e) => updateField("LotExtras", e.target.value)}
                      error={formErrors["LotExtras"] !== ""}
                      helperText={formErrors["LotExtras"]}
                      fullWidth
                      InputProps={{
                        startAdornment: (
                          <InputAdornment
                            position="start"
                            sx={{
                              ml: 1,
                            }}
                          >
                            $
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Grid>
                  <Grid item xs={9} display="flex" justifyContent="flex-end">
                    <Typography
                      variant="body1"
                      fontWeight="bold"
                      sx={{
                        alignSelf: "center",
                      }}
                    >
                      Total Lot Extras(Exit Strategy)
                    </Typography>
                  </Grid>
                  <Grid item xs={3}>
                    <TextField
                      variant="filled"
                      size="small"
                      inputProps={{
                        min: 0,
                      }}
                      fullWidth
                      value={runningTotals.lotExtras || 0}
                      disabled
                      InputProps={{
                        startAdornment: (
                          <InputAdornment
                            position="start"
                            sx={{
                              ml: 1,
                            }}
                          >
                            $
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Grid>
                  <Grid
                    item
                    xs={9}
                    display="flex"
                    justifyContent="flex-end"
                    mt={5}
                  >
                    <Typography
                      variant="body1"
                      fontWeight="bold"
                      sx={{
                        alignSelf: "center",
                      }}
                    >
                      Subtotal Labor & Material
                    </Typography>
                  </Grid>
                  <Grid item xs={3} mt={5}>
                    <TextField
                      variant="filled"
                      size="small"
                      type="number"
                      inputProps={{
                        min: 0,
                      }}
                      value={subtotalLaborMaterial || 0}
                      disabled
                      fullWidth
                      InputProps={{
                        startAdornment: (
                          <InputAdornment
                            position="start"
                            sx={{
                              ml: 1,
                            }}
                          >
                            $
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Grid>
                </Grid>
              </Box>
              <Box pt={8} id="options">
                <Accordion elevation={0} expanded={true}>
                  <AccordionSummary>
                    <Box
                      sx={{
                        display: "flex",
                        flex: 1,
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <Stack direction="row" spacing={2}>
                        <Icon fontSize="large">
                          <TuneIcon
                            fontSize="large"
                            sx={{
                              color: "#ACACAC",
                            }}
                          />
                        </Icon>
                        <Typography
                          fontSize={20}
                          sx={{
                            alignSelf: "end",
                          }}
                        >
                          Options
                        </Typography>
                      </Stack>
                      <Typography
                        fontSize={24}
                        sx={{
                          alignSelf: "end",
                          color: "#25D72C",
                          fontWeight: "bold",
                        }}
                      >
                        {`$${numberWithCommas(runningTotals?.options || 0)} `}
                      </Typography>
                    </Box>
                  </AccordionSummary>
                </Accordion>
                <Divider />
                <Grid container mt={4} spacing={2}>
                  <Grid item xs={9} display="flex">
                    <Typography
                      variant="body1"
                      fontWeight="bold"
                      sx={{
                        alignSelf: "center",
                      }}
                    >
                      Cost of Options with Contract
                    </Typography>
                  </Grid>
                  <Grid item xs={3}>
                    <TextField
                      disabled={mode === "review"}
                      type="number"
                      inputProps={{
                        min: 0,
                      }}
                      variant="outlined"
                      size="small"
                      value={
                        optionsSection.CostOfOptionsWithContract === undefined
                          ? ""
                          : optionsSection.CostOfOptionsWithContract.toString().replace(/^0+/, '')
                      }
                      onChange={(e) =>
                        updateField("CostOfOptionsWithContract", e.target.value)
                      }
                      error={formErrors["CostOfOptionsWithContract"] !== ""}
                      helperText={formErrors["CostOfOptionsWithContract"]}
                      fullWidth
                      InputProps={{
                        startAdornment: (
                          <InputAdornment
                            position="start"
                            sx={{
                              ml: 1,
                            }}
                          >
                            $
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Grid>
                  <Grid item xs={9} display="flex" justifyContent="flex-end">
                    <Typography
                      variant="body1"
                      fontWeight="bold"
                      sx={{
                        alignSelf: "center",
                      }}
                    >
                      Cost of Total Options
                    </Typography>
                  </Grid>
                  <Grid item xs={3}>
                    <TextField
                      variant="filled"
                      size="small"
                      type="number"
                      inputProps={{
                        min: 0,
                      }}
                      disabled
                      value={runningTotals.options}
                      fullWidth
                      InputProps={{
                        startAdornment: (
                          <InputAdornment
                            position="start"
                            sx={{
                              ml: 1,
                            }}
                          >
                            $
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Grid>
                  <Grid
                    item
                    xs={9}
                    display="flex"
                    justifyContent="flex-end"
                    mt={5}
                  >
                    <Typography
                      variant="body1"
                      fontWeight="bold"
                      sx={{
                        alignSelf: "center",
                      }}
                    >
                      Total Labor & Materials
                    </Typography>
                  </Grid>
                  <Grid item xs={3} mt={5}>
                    <TextField
                      variant="filled"
                      size="small"
                      type="number"
                      inputProps={{
                        min: 0,
                      }}
                      disabled
                      value={totalLaborMaterial}
                      fullWidth
                      InputProps={{
                        startAdornment: (
                          <InputAdornment
                            position="start"
                            sx={{
                              ml: 1,
                            }}
                          >
                            $
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Grid>
                </Grid>
              </Box>
              <Box pt={8} id="softCost">
                <Accordion elevation={0} expanded={true}>
                  <AccordionSummary>
                    <Box
                      sx={{
                        display: "flex",
                        flex: 1,
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <Stack direction="row" spacing={2}>
                        <Icon fontSize="large">
                          <RequestQuoteIcon
                            fontSize="large"
                            sx={{
                              color: "#ACACAC",
                            }}
                          />
                        </Icon>
                        <Typography
                          fontSize={20}
                          sx={{
                            alignSelf: "end",
                          }}
                        >
                          Soft Cost
                        </Typography>
                      </Stack>
                      <Typography
                        fontSize={24}
                        sx={{
                          alignSelf: "end",
                          color: "#25D72C",
                          fontWeight: "bold",
                        }}
                      >
                        {`$${numberWithCommas(runningTotals?.softCost)}`}
                      </Typography>
                    </Box>
                  </AccordionSummary>
                </Accordion>
                <Divider />
                <Grid container mt={4} spacing={2}>
                  <Grid item xs={9} display="flex">
                    <Typography
                      variant="body1"
                      fontWeight="bold"
                      sx={{
                        alignSelf: "center",
                      }}
                    >
                      Lot Cost/(Code F)(Note 2)(+/1 Deviation)
                    </Typography>
                  </Grid>
                  <Grid item xs={3}>
                    <TextField
                      disabled={mode === "review"}
                      variant="outlined"
                      size="small"
                      type="number"
                      inputProps={{
                        min: 0,
                      }}
                      value={
                        softCostSection.LotCost === undefined
                          ? ""
                          : softCostSection.LotCost.toString().replace(/^0+/, '')
                      }
                      onChange={(e) => updateField("LotCost", e.target.value)}
                      error={formErrors["LotCost"] !== ""}
                      helperText={formErrors["LotCost"]}
                      fullWidth
                      InputProps={{
                        startAdornment: (
                          <InputAdornment
                            position="start"
                            sx={{
                              ml: 1,
                            }}
                          >
                            $
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Grid>
                  <Grid item xs={9} display="flex">
                    <Typography
                      variant="body1"
                      fontWeight="bold"
                      sx={{
                        alignSelf: "center",
                      }}
                    >
                      Discretionary - Buyer Closing Costs paid by Seller
                    </Typography>
                  </Grid>
                  <Grid item xs={3}>
                    <TextField
                      disabled={mode === "review"}
                      variant="outlined"
                      size="small"
                      type="number"
                      inputProps={{
                        min: 0,
                      }}
                      value={
                        softCostSection.Discretionary === undefined
                          ? ""
                          : softCostSection.Discretionary.toString().replace(/^0+/, '')
                      }
                      onChange={(e) =>
                        updateField("Discretionary", e.target.value)
                      }
                      error={formErrors["Discretionary"] !== ""}
                      helperText={formErrors["Discretionary"]}
                      fullWidth
                      InputProps={{
                        startAdornment: (
                          <InputAdornment
                            position="start"
                            sx={{
                              ml: 1,
                            }}
                          >
                            $
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Grid>
                  <Grid
                    item
                    xs={9}
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "flex-start",
                    }}
                  >
                    <Typography variant="body1" fontWeight="bold">
                      Realtor Commissions-(3%)/(Category H)
                    </Typography>
                    <FormControl>
                      <FormLabel
                        sx={{
                          textAlign: "start",
                          mt: 1,
                          ml: 1,
                        }}
                      ></FormLabel>
                    </FormControl>
                  </Grid>
                  <Grid item xs={3}>
                    <TextField
                      disabled={mode === "review"}
                      variant="outlined"
                      size="small"
                      type="number"
                      inputProps={{
                        min: 0,
                      }}
                      sx={{
                        visibility: softCostSection.HasRealtor
                          ? "visible"
                          : "hidden",
                      }}
                      value={
                        softCostSection.RealtorCommissions === undefined
                          ? ""
                          : softCostSection.RealtorCommissions.toString().replace(/^0+/, '')
                      }
                      onChange={(e) =>
                        updateField("RealtorCommissions", e.target.value)
                      }
                      error={formErrors["RealtorCommissions"] !== ""}
                      helperText={formErrors["RealtorCommissions"]}
                      fullWidth
                      InputProps={{
                        startAdornment: (
                          <InputAdornment
                            position="start"
                            sx={{
                              ml: 1,
                            }}
                          >
                            $
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Grid>
                  <Grid
                    item
                    xs={9}
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "flex-start",
                    }}
                  >
                    <Typography variant="body1" fontWeight="bold">
                      Has Realtor?
                    </Typography>
                    <FormControl>
                      <FormLabel
                        sx={{
                          textAlign: "start",
                          mt: 1,
                          ml: 1,
                        }}
                      ></FormLabel>
                    </FormControl>
                  </Grid>
                  <Grid item xs={3}>
                    <FormControl>
                      <FormLabel
                        sx={{
                          textAlign: "start",
                          mt: 1,
                          ml: 1,
                        }}
                      ></FormLabel>
                      <RadioGroup
                        row
                        value={softCostSection.HasRealtor ? "true" : "false"}
                        onChange={(e) => {
                          setSoftCostSection({
                            ...softCostSection,
                            HasRealtor: e.target.value === "true",
                            RealtorCommissions:
                              e.target.value === "true"
                                ? softCostSection.RealtorCommissions
                                : 0,
                          });
                        }}
                      >
                        <FormControlLabel
                          value="true"
                          control={<Radio />}
                          label="Yes"
                        />
                        <FormControlLabel
                          value="false"
                          control={<Radio />}
                          label="No"
                        />
                      </RadioGroup>
                    </FormControl>
                  </Grid>
                  <Grid item xs={9} display="flex">
                    <Typography
                      variant="body1"
                      fontWeight="bold"
                      sx={{
                        alignSelf: "center",
                      }}
                    >
                      Special Marketing Incentive
                    </Typography>
                  </Grid>
                  <Grid item xs={3}>
                    <TextField
                      disabled={mode === "review"}
                      variant="outlined"
                      size="small"
                      type="number"
                      inputProps={{
                        min: 0,
                      }}
                      value={
                        softCostSection.SpecialMarketingIncentive === undefined
                          ? ""
                          : softCostSection.SpecialMarketingIncentive.toString().replace(/^0+/, '')
                      }
                      onChange={(e) =>
                        updateField("SpecialMarketingIncentive", e.target.value)
                      }
                      error={formErrors["SpecialMarketingIncentive"] !== ""}
                      helperText={formErrors["SpecialMarketingIncentive"]}
                      fullWidth
                      InputProps={{
                        startAdornment: (
                          <InputAdornment
                            position="start"
                            sx={{
                              ml: 1,
                            }}
                          >
                            $
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Grid>
                  <Grid item xs={9} display="flex">
                    <Typography
                      variant="body1"
                      fontWeight="bold"
                      sx={{
                        alignSelf: "center",
                      }}
                    >
                      Realtor Commissions adjusted
                    </Typography>
                  </Grid>
                  <Grid item xs={3}>
                    <TextField
                      disabled={mode === "review"}
                      variant="outlined"
                      size="small"
                      type="number"
                      inputProps={{
                        min: 0,
                      }}
                      value={
                        softCostSection.RealtorCommissionsadjusted === undefined
                          ? ""
                          : softCostSection.RealtorCommissionsadjusted.toString().replace(/^0+/, '')
                      }
                      onChange={(e) =>
                        updateField(
                          "RealtorCommissionsadjusted",
                          e.target.value
                        )
                      }
                      error={formErrors["RealtorCommissionsadjusted"] !== ""}
                      helperText={formErrors["RealtorCommissionsadjusted"]}
                      fullWidth
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">-$</InputAdornment>
                        ),
                      }}
                    />
                  </Grid>
                  <Grid item xs={9} display="flex">
                    <Typography
                      variant="body1"
                      fontWeight="bold"
                      sx={{
                        alignSelf: "center",
                      }}
                    >
                      In-House Commissions-(2%)/(Category I)
                    </Typography>
                  </Grid>
                  <Grid item xs={3}>
                    <TextField
                      disabled={mode === "review"}
                      variant="outlined"
                      size="small"
                      type="number"
                      inputProps={{
                        min: 0,
                      }}
                      value={
                        softCostSection.InHouseCommissions === undefined
                          ? ""
                          : softCostSection.InHouseCommissions.toString().replace(/^0+/, '')
                      }
                      onChange={(e) =>
                        updateField("InHouseCommissions", e.target.value)
                      }
                      error={formErrors["InHouseCommissions"] !== ""}
                      helperText={formErrors["InHouseCommissions"]}
                      fullWidth
                      InputProps={{
                        startAdornment: (
                          <InputAdornment
                            position="start"
                            sx={{
                              ml: 1,
                            }}
                          >
                            $
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Grid>
                  <Grid item xs={9} display="flex">
                    <Typography
                      variant="body1"
                      fontWeight="bold"
                      sx={{
                        alignSelf: "center",
                      }}
                    >
                      Interim Interest Budget(#8140)
                    </Typography>
                  </Grid>
                  <Grid item xs={3}>
                    <TextField
                      disabled={mode === "review"}
                      variant="outlined"
                      size="small"
                      type="number"
                      inputProps={{
                        min: 0,
                      }}
                      value={
                        softCostSection.InterimInterestBudget === undefined
                          ? ""
                          : softCostSection.InterimInterestBudget.toString().replace(/^0+/, '')
                      }
                      onChange={(e) =>
                        updateField("InterimInterestBudget", e.target.value)
                      }
                      error={formErrors["InterimInterestBudget"] !== ""}
                      helperText={formErrors["InterimInterestBudget"]}
                      fullWidth
                      InputProps={{
                        startAdornment: (
                          <InputAdornment
                            position="start"
                            sx={{
                              ml: 1,
                            }}
                          >
                            $
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Grid>
                  <Grid item xs={9} display="flex">
                    <Typography
                      variant="body1"
                      fontWeight="bold"
                      sx={{
                        alignSelf: "center",
                      }}
                    >
                      Closing Cost Budget/(Category K)
                    </Typography>
                  </Grid>
                  <Grid item xs={3}>
                    <TextField
                      disabled={mode === "review"}
                      variant="outlined"
                      size="small"
                      type="number"
                      inputProps={{
                        min: 0,
                      }}
                      value={
                        softCostSection.ClosingCostBudget === undefined
                          ? ""
                          : softCostSection.ClosingCostBudget.toString().replace(/^0+/, '')
                      }
                      onChange={(e) =>
                        updateField("ClosingCostBudget", e.target.value)
                      }
                      error={formErrors["ClosingCostBudget"] !== ""}
                      helperText={formErrors["ClosingCostBudget"]}
                      fullWidth
                      InputProps={{
                        startAdornment: (
                          <InputAdornment
                            position="start"
                            sx={{
                              ml: 1,
                            }}
                          >
                            $
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Grid>
                  <Grid item xs={9} display="flex">
                    <Typography
                      variant="body1"
                      fontWeight="bold"
                      sx={{
                        alignSelf: "center",
                      }}
                    >
                      Builder Bonus (#8195)
                    </Typography>
                  </Grid>
                  <Grid item xs={3}>
                    <TextField
                      disabled={mode === "review"}
                      variant="outlined"
                      size="small"
                      type="number"
                      inputProps={{
                        min: 0,
                      }}
                      value={
                        softCostSection.BuilderBonus === undefined
                          ? ""
                          : softCostSection.BuilderBonus.toString().replace(/^0+/, '')
                      }
                      onChange={(e) =>
                        updateField("BuilderBonus", e.target.value)
                      }
                      error={formErrors["BuilderBonus"] !== ""}
                      helperText={formErrors["BuilderBonus"]}
                      fullWidth
                      InputProps={{
                        startAdornment: (
                          <InputAdornment
                            position="start"
                            sx={{
                              ml: 1,
                            }}
                          >
                            $
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Grid>
                  <Grid
                    item
                    xs={9}
                    display="flex"
                    justifyContent="flex-end"
                    mt={5}
                  >
                    <Typography
                      variant="body1"
                      fontWeight="bold"
                      sx={{
                        alignSelf: "center",
                      }}
                    >
                      Subtotal Soft Cost
                    </Typography>
                  </Grid>
                  <Grid item xs={3} mt={5}>
                    <TextField
                      variant="filled"
                      size="small"
                      inputProps={{
                        min: 0,
                      }}
                      disabled
                      value={runningTotals.softCost}
                      fullWidth
                      InputProps={{
                        startAdornment: (
                          <InputAdornment
                            position="start"
                            sx={{
                              ml: 1,
                            }}
                          >
                            $
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Grid>
                </Grid>
              </Box>
              {mode === "review" && (
                <Stack
                  direction="row"
                  justifyContent="space-between"
                  spacing={2}
                  my={10}
                >
                  <Box>
                    <Button
                      variant="contained"
                      onClick={() => {
                        setMode("edit");
                      }}
                    >
                      EDIT
                    </Button>
                  </Box>
                  <Box>
                    <Button
                      onClick={() => {
                        setOpenConfirmation(true);
                      }}
                      disabled={!ableToCreate}
                      variant="contained"
                      sx={{
                        maxWidth: 300,
                        mr: 2,
                      }}
                    >
                      Save Changes
                    </Button>
                    {/* <Button
                    variant="contained"
                    sx={{
                      maxWidth: 300,
                    }}
                    onClick={() => {
                      handleSave(true);
                    }}
                    disabled={!ableToCreate}
                  >
                    Save and create another
                  </Button> */}
                  </Box>
                </Stack>
              )}

              {mode === "edit" && (
                <Stack direction="row" justifyContent="flex-end" my={10}>
                  <Button
                    variant="contained"
                    onClick={() => {
                      if (ableToCreate) {
                        setMode("review");
                      } else {
                        alert("Please fill out the general information");
                      }
                    }}
                    sx={{
                      maxWidth: 300,
                    }}
                    disabled={!checkFormValidity()}
                  >
                    Review
                  </Button>
                </Stack>
              )}
            </Box>
            <Drawer
              variant="permanent"
              anchor="right"
              sx={{
                width: drawerWidth,
                flexShrink: 0,
                [`& .MuiDrawer-paper`]: {
                  width: drawerWidth,
                  boxSizing: "border-box",
                },
              }}
            >
              <Toolbar />
              <Box
                sx={{
                  overflow: "auto",
                  backgroundColor: "rgba(183, 176, 255, 0.13)",
                  flex: 1,
                }}
              >
                <List>
                  {navbarItems.map((nav, index) => (
                    <a
                      href={nav.href}
                      key={index}
                      style={{
                        textDecoration: "none",
                      }}
                    >
                      <ListItem key={nav.id} disablePadding>
                        <ListItemButton>
                          <ListItemIcon>
                            <Icon>{nav.icon}</Icon>
                          </ListItemIcon>
                          <ListItemText
                            primary={nav.section}
                            sx={{
                              color: "rgba(0, 0, 0, 0.87) !important",
                            }}
                          />
                        </ListItemButton>
                      </ListItem>
                    </a>
                  ))}
                </List>
                <Divider />
              </Box>
            </Drawer>
          </Box>
        </Container>
      </LocalizationProvider>
    </ThemeProvider>
  );
}
